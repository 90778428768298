import React, { useContext } from "react";
import { Stack, Main, Sidebar } from "@layout";
import { Box, Heading } from "theme-ui";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import NewsletterExpanded from "@widgets/NewsletterExpanded";
import LoginCard from "@widgets/LoginCard";
import { Text } from "theme-ui";
import { FAQ } from "../utils/faq";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AuthContext } from "../../../core/src/context/auth";
import { useColorMode } from "theme-ui";
import ContactInfo from "@widgets/ContactInfo";
import "./product.css";

export default ({ ...props }) => {
  const { pageContext: { services = {} } = {} } = props;
  const { user } = useContext(AuthContext);

  const [colorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const bgStyles = {
    backgroundColor: isDark ? "#2d3748 " : "white",
    color: isDark ? "white " : "white"
  };
  console.log(user);
  const annualFeatures = [
    "Unlimited questions",
    "Cloud backups",
    "Weekly Q & A",
    "Free LaCroix (coconut only)",
    "Access to private Slack group"
  ];
  const monthlyFeatures = [
    "Unlimited questions",
    "Cloud backups",
    "Weekly Q & A",
    "Free LaCroix (coconut only)",
    "FB Group Support"
  ];
  return (
    <>
      <Seo title="About us" />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header="Let's Ace Algorithms and Data Structures 🤓 🎆"
            subheader="One streamlined platform for all your algorithm and data structure
            needs. 200+ curated questions. Crystal clear explanations."
          />
          {/*<Stack>
            <section className="pricing">
              <div
                className="columns"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "stretch",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: "450px",
                    margin: "40px",
                    backgroundColor: "white",
                    boxShadow: "0 5px 20px #3c247333",
                    borderRadius: "5px",
                    overflow: "hidden",
                    position: "relative",
                    borderTop: "1px solid transparent",
                    textAlign: "center",
                  }}
                >
                  <h2
                    style={{ padding: 10, marginBottom: 0, color: "#3e2373" }}
                  >
                    Monthly Plan
                  </h2>
                  <p
                    className="price"
                    style={{
                      position: "absolute",
                      backgroundColor: " #3e2373",
                      color: "white",
                      textAlign: "center",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      width: "200px",
                      right: "-50px",
                      top: "20px",
                      transform: "rotate(45deg)",
                      fontSize: "large",
                    }}
                  >
                    $20/mo
                  </p>
                  <ul style={{ padding: 0 }}>
                    {monthlyFeatures.map((feature, index) => {
                      if (index % 2 == 0) {
                        return (
                          <li style={{ padding: 10, color: "#3e2373" }}>
                            {feature}
                          </li>
                        );
                      }
                      return (
                        <li
                          style={{
                            padding: 10,
                            backgroundColor: "#faf9fa",
                            color: "#3e2373",
                          }}
                        >
                          {feature}
                        </li>
                      );
                    })}
                  </ul>
                  <div style={{ flexGrow: 1 }}></div>
                  <a
                    href="#"
                    style={{
                      display: "block",
                      background: "linear-gradient(135deg, #d66582, #6e3978)",
                      color: "white",
                      backgroundColor: " #b72367dd",
                      textAlign: "center",
                      textDecoration: "none",
                      padding: "10px 20px",
                      fontWeight: 700,
                    }}
                  >
                    Coming soon
                  </a>
                </div>
                <div
                  className="columns"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: "450px",
                    margin: "40px",
                    backgroundColor: "white",
                    boxShadow: "0 5px 20px #3c247333",
                    borderRadius: "5px",
                    overflow: "hidden",
                    position: "relative",
                    borderTop: "1px solid transparent",
                    textAlign: "center",
                  }}
                >
                  <h2
                    style={{ padding: 10, marginBottom: 10, color: "#3e2373" }}
                  >
                    Annual Plan
                  </h2>
                  <p
                    className="price"
                    style={{
                      position: "absolute",
                      backgroundColor: " #3e2373",
                      color: "white",
                      textAlign: "center",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      width: "200px",
                      right: "-50px",
                      top: "20px",
                      transform: "rotate(45deg)",
                      fontSize: "large",
                    }}
                  >
                    $80/Y
                  </p>
                  <ul style={{ padding: 0 }}>
                    {annualFeatures.map((feature, index) => {
                      if (index % 2 == 0) {
                        return (
                          <li style={{ padding: 10, color: "#3e2373" }}>
                            {feature}
                          </li>
                        );
                      }
                      return (
                        <li
                          style={{
                            padding: 10,
                            backgroundColor: "#faf9fa",
                            color: "#3e2373",
                          }}
                        >
                          {feature}
                        </li>
                      );
                    })}
                  </ul>
                  <div style={{ flexGrow: 1 }}></div>
                  <a
                    href="#"
                    style={{
                      display: "block",
                      background: "linear-gradient(135deg, #d66582, #6e3978)",
                      color: "white",
                      backgroundColor: " #b72367dd",
                      textAlign: "center",
                      textDecoration: "none",
                      padding: "10px 20px",
                      fontWeight: 700,
                    }}
                  >
                    Coming soon
                  </a>
                </div>
              </div>
            </section>
            </Stack>*/}
          <h2>What is devclass?</h2>
          <Text variant="p">
            Simply put, Devclass is the resource we wish existed when we were
            learning data structures and algorithms. We've spent 1000+ hours
            building the perfect coding interview preparation syllabus.{" "}
          </Text>
          <Text variant="p">
            Universities aim to make you computer science researchers. Interview
            prep platforms assume you know algorithm patterns and data strucutre
            concepts. We don't assume anything. We pride ourselves on making
            crystal clear explanation for humans!
          </Text>
          <Text variant="p">
            We're building devclass with the goal of making amazing explanations
            accessible to all. This is why <b>it's completely free!</b>
          </Text>
          <h2>FAQ</h2>

          {FAQ.map(faq => (
            <div style={{ padding: 5, color: bgStyles.color }}>
              <Accordion style={{ backgroundColor: faq.color }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon style={{ color: bgStyles.color }} />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Text
                    style={{
                      color: bgStyles.color,
                      fontWeight: "700",
                      fontSize: 18
                    }}
                  >
                    {faq.title}
                  </Text>
                </AccordionSummary>
                <AccordionDetails style={{ color: bgStyles.color }}>
                  {faq.description}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
          <Divider />
        </Main>
        <Divider />
        <Sidebar>
          {!user && (
            <div style={{ marginBottom: 10 }}>
              <LoginCard />
            </div>
          )}
          <ContactInfo />
        </Sidebar>
      </Stack>
    </>
  );
};
