import React from "react";
import { Link } from "gatsby";
import Section from "@components/Section";
import { Button } from "theme-ui";
import { Text } from "theme-ui";

export const FAQ = [
  {
    title: "Why Should I Master Algorithms and Data Structures?",
    color: "#27ae60",
    description: (
      <div>
        <Text variant="p">
          Algorithms and data structures are fascinating and power every piece
          of technology we touch! Whether it's your mobile device, laptop or
          even your smart watch, algorithms are <i>everywhere.</i> Because of
          the omni presence of algorithms and data structures it is likely the
          single most high impact investment you can make in your software
          engineering career. Let's list some of the benefits.
          <ul>
            <li>
              <b>Lifelong knowledge, always applicable: </b>
              This isn't a new React library, the latest version of Tensorflow
              or the last version of iOS. This knowledge will be relevant and up
              to date for the entierety of your career.
            </li>
            <li>
              <b>Pattern Recognition: </b>We focus on building a deep
              understanding and intuition. No more memorizations!
            </li>
            <li>
              <b>Acing University Exams: </b>Acing university exams. Lorem ipsum
              doler est aholod.
            </li>
            <li>
              <b>Crush the Coding Interview: </b>
              Feeling confident to face the job market and crush the coding
              interview
            </li>
          </ul>
          The benefits are clear! But still, software engineers agonize over
          learning data structures and algorithms, and honestly, most engineers
          aren't great at them. So where's the disconnect?
        </Text>
        <Section>
          <Button variant="primary" as={Link} to="/signup">
            Sign me up!
          </Button>
        </Section>
      </div>
    )
  },
  {
    title: "Why is it so hard to master algorithms and data structures?",
    color: "#2980b9",
    description: (
      <div>
        <Text variant="p">
          Let's look at why mastering algorithms and data structures is so
          tough!
          <ul>
            <li>
              <b>Resource overload:</b> Don't grind endless 400 interview
              questions.
            </li>
            <li>
              <b>Crazy prices: </b> Some courses charge thousands of dollars!
            </li>
            <li>
              <b>Scattered resources: </b>Infinite DS and algo questions online
            </li>
            <li>
              <b>FOMO created: </b>
              The amount of resources available are absolutely staggering! The
              paradox of choice can lead to analysis paralysis where candidates
              spend more time aggregating and evaluating materials instead of
              actually preparing!
            </li>
          </ul>
        </Text>
        <Section>
          <Button variant="primary" as={Link} to="/signup">
            I'm ready to crush algos! Make me a member!
          </Button>
        </Section>
      </div>
    )
  },
  {
    title: "Why is this free?",
    color: "#8e44ad",
    description: (
      <div>
        <Text variant="p">
          Omer and Assaf have been on both sides of the interview table over the
          past decade. The past several years has seen a{" "}
          <b>
            proliferation of high quality paid interview preperation resources.
            This raises the bar across the industry, inevitably leaving aspiring
            engineers who can't afford these courses hanging out to dry.{" "}
          </b>{" "}
          Course prices range can reach up to <b>thousands</b> of dollars!
          Landing your dream job can be life changing -- we know that. And that
          is exactly why, <b>everyone</b> should have access to high quality
          interview prep resources! Omer and Assaf have poured 1000+ hours into
          this platform, aiming to create the best resource online. We're always
          open for feedback! Please reach out if you have any ideas :)
        </Text>
        <Section>
          <Button variant="primary" as={Link} to="/signup">
            This is free?! Sign me up!
          </Button>
        </Section>
      </div>
    )
  },

  {
    title: "How is Devclass different?",
    color: "#e67e22",
    description: (
      <div>
        <Text variant="p">
          <ul>
            <li>
              <b>Resource overload</b> 1 streamlined platform with all of your
              resource needs.
            </li>
            <li>
              <b>It's completely free: </b> Everyone should have access to high
              quality learning materials, regardless of social economic status.
              No more crazy prices!
            </li>
            <li>
              <b>Curated questions: </b>Our questions are hand picked. We select
              questions that have real life relevance and connect between theory
              and practicality so you walk away with real knowledge.
            </li>
            <li>
              <b>A caring community: </b> Omer and Assaf are here for you and
              intent on building a caring, supportive community. Join us!
            </li>
          </ul>
        </Text>
        <Section>
          <Button variant="primary" as={Link} to="/signup">
            This is the program for me. I'm in!
          </Button>
        </Section>
      </div>
    )
  },

  {
    title: "I'm still debating. Why is now the right time?",
    color: "#e74c3c",
    description: (
      <div>
        <Text variant="p">
          What are my alternatives?
          <ul>
            <li>
              <b>The best time to plant a tree was 20 years ago.</b> The second
              best time is now :)
            </li>
            <li>
              <b>
                Keeping your interview skills sharp will build your confidence
              </b>
              It's absolutely free, so no reason to procrasinate :)
            </li>
            <li>
              <b>Take control of your career </b>
              With top level preparation, take control of your career and pursue
              that dream job.
            </li>
          </ul>
        </Text>
        <Section>
          <Button variant="primary" as={Link} to="/signup">
            It's FREE and available now. No more excuses!
          </Button>
        </Section>
      </div>
    )
  }
];
